import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import RedWorm from "../../data/icons/RedWorm.svg"
import BluelineTop2 from "../../data/icons/bluelineTop2.svg"
import BluelineBot2 from "../../data/icons/bluelineBot2.svg"

const Container = styled.div`
width:100%;

& h1 {
    text-align:center;
    margin:20px;
}
.blueline {
    rigth:0;
    width:100%;
    height:auto;
    display: block;
    z-index:-1;
    & .num2 {
        
        bottom:1.35vw;
    }
}
`

const Wrapper = styled.div`
width:100%;
height: calc(100vh - 18vw - 274px);
position:relative;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
& svg {
    margin:auto;
    -webkit-animation: rotating 4s 0s linear infinite;
            animation: rotating 4s 0s linear infinite;

    @-webkit-keyframes rotating {
        0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
        50% { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
        100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
    }

    @keyframes rotating {
        0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
        50% { -webkit-transform: rotate(180deg); transform: rotate(180deg); }
        100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
    }
}
`

export default function Page404({data}){

    return(
        <Container>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>404</title>
                <html lang="de"/>
            </Helmet>
            <Layout>
                <BluelineTop2 className="blueline"/>
                <h1>404 Page not found</h1>
                <Wrapper>
                    <RedWorm className="worm"/>
                </Wrapper>
                <BluelineBot2 className="blueline num2"/>
            </Layout>
        </Container>
    )
}
